<template>
  <b-card>
    <!-- media -->
    <b-media no-body>
      <b-media-aside>
        <span>
          <b-img
            v-if="form_data.path"
            ref="previewEl"
            rounded
            :src="form_data.path[0].path"
            height="80"
          />
          <b-img
            v-else
            ref="previewEl"
            rounded
            :src="require('@/assets/images/avatars/13-small.png')"
            height="80"
          />
        </span>

        <!--/ avatar -->
      </b-media-aside>

      <b-media-body class="mt-75 ml-75">
        <!-- upload button -->
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          size="sm"
          class="mb-75 mr-75"
          @click="$refs.refInputEl.$el.click()"
        >
          {{ $t("g.UploadPhoto") }}
        </b-button>
        <b-form-file
          ref="refInputEl"
          v-model="form_data.path"
          accept=".jpg, .png, .gif"
          :hidden="true"
          plain
        />
        <!--/ upload button -->
      </b-media-body>
    </b-media>
    <!--/ media -->

    <!-- form -->
    <validation-observer ref="form">
      <b-form class="mt-2">
        <b-row>
          <b-col sm="6">
            <b-form-group :label="$t('g.firstName')" label-for="first_name">
              <validation-provider
                #default="{ errors }"
                name="first name"
                rules="required"
              >
                <b-form-input
                  v-model="form_data.first_name"
                  placeholder="first name"
                  name="first_name"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{
                  errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
                }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col sm="6">
            <b-form-group :label="$t('g.lastName')" label-for="last_name">
              <validation-provider
                #default="{ errors }"
                name="last name"
                rules="required"
              >
                <b-form-input
                  v-model="form_data.last_name"
                  name="last_name"
                  placeholder="last name"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{
                  errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
                }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col sm="6">
            <b-form-group :label="$t('g.email')" label-for="account-e-mail">
              <b-form-input
                name="email"
                type="email"
                placeholder="Email"
                :value="data.email"
                disabled
              />
            </b-form-group>
          </b-col>
          <b-col sm="6">
            <b-form-group :label="$t('g.numberPhone')" label-for="phone">
              <b-form-input
                name="phone"
                placeholder="phone"
                :value="data.phone[0].phone"
                disabled
                type="number"
              />
            </b-form-group>
          </b-col>
          <!-- birthDate -->
          <b-col md="6">
            <b-form-group :label="$t('g.birthDate')">
              <b-form-datepicker v-model="form_data.birthdate" />
            </b-form-group>
          </b-col>

          <!-- ** birthDate -->
          <!-- categories -->
          <!-- <b-col md="6">
            <b-form-group :label="$t('g.categories')" label-for="categories">
              <validation-provider
                #default="{ errors }"
                name="categories"
                rules="required"
              >
                <v-select
                  v-model="form_data.category_id"
                  :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
                  :options="categories_options"
                  :state="errors.length > 0 ? false : null"
                  multiple
                  :reduce="(country) => country.id"
                />

                <small class="text-danger">{{
                  errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
                }}</small>
              </validation-provider>
            </b-form-group>
          </b-col> -->
          <b-col md="6">
            <b-form-group :label="$t('g.Country')" label-for="Country">
              <validation-provider
                #default="{ errors }"
                name="Country"
                rules="required"
              >
                <v-select
                  v-model="form_data.country_id"
                  :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
                  :options="country_options"
                  :state="errors.length > 0 ? false : null"
                  :reduce="(country) => country.value"
                />

                <small class="text-danger">{{
                  errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
                }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mt-2 mr-1"
              @click.prevent="sendForm"
              :disabled="isPending"
            >
              {{ $t("g.SaveChanges") }}
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import {
  BFormFile,
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BCard,
  BMedia,
  BMediaAside,
  BMediaBody,
  BImg,
  BFormDatepicker,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import vSelect from "vue-select";
import { required, email, confirmed } from "@validations";

export default {
  components: {
    BButton,
    BForm,
    BImg,
    BFormFile,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BMedia,
    BMediaAside,
    BMediaBody,
    BFormDatepicker,
    vSelect,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      form_data: {
        first_name: null,
        last_name: null,
        birthdate: null,
        // category_id: [],
        path: [],
        country_id: null,
      },
      // categories_options: [],
      country_options: [],
      isPending: false,
    };
  },

  beforeMount() {
    this.form_data.first_name = this.data.first_name;
    this.form_data.last_name = this.data.last_name;
    this.form_data.birthdate = this.data?.birthdate;
    this.form_data.country_id = this.data?.country?.id;
    this.form_data.path = this.data?.media;
    this.form_data.categories =
      this.data?.categories?.map((item) => item.id) ?? [];
    this.getAllCategories();
    this.getAllCountry();
  },

  methods: {
    //  Sweet Toast Function config
    makeToast(variant = null, body, title) {
      this.$bvToast.toast(this.$t(body), {
        title: this.$t(title),
        variant,
        solid: true,
      });
    },

    getAllCountry() {
      this.$http
        .get("admin/countries")
        .then((response) => {
          if (response.status === 200) {
            this.country_options = response.data.data.map((item) => ({
              value: item.id,
              label: item.name,
            }));
          }
        })
        .catch((error) => {
          console.log(error);
          this.pageStatus(false, true);
        });
    },

    getAllCategories() {
      this.$http
        .get("admin/categories")
        .then((response) => {
          if (response.status === 200) {
            this.categories_options = response.data.data.map((item) => ({
              id: item.id,
              label: item.name,
            }));
          }
        })
        .catch((error) => {
          console.log(error);
          this.pageStatus(false, true);
        });
    },

    sendForm() {
      this.$refs.form.validate().then((success) => {
        if (success) {
          let copyFormData = { ...this.form_data };

          if (this.form_data.path[0]?.path === this.data.media[0].path) {
            delete copyFormData.path;
          }
          let formData = new FormData();
          for (let item in copyFormData) {
            if (item !== "category_id") {
              formData.append(item, copyFormData[item]);
            }
          }
          // for (let i = 0; i < copyFormData.category_id.length; ++i) {
          //   formData.append(`category_id[${i}]`, copyFormData.category_id[i]);
          // }
          // console.log(copyFormData);

          this.isPending = true;

          this.$http
            .post(`web/users/${this.data.id}?_method=put`, formData)
            .then((res) => {
              if (res.status === 200) {
                this.makeToast(
                  "success",
                  this.$t("g.send.successBody"),
                  this.$t("g.send.successTitle")
                );
              }
              this.isPending = false;
            })
            .catch((err) => {
              for (const key in err.response.data.errors) {
                this.makeToast(
                  "danger",
                  err.response.data.errors[key][0],
                  this.$t("g.send.errorTitle")
                );
              }
              console.log(err);
              this.isPending = false;
            });
        } else {
          this.makeToast(
            "warning",
            this.$t("g.send.invalidInputBody"),
            this.$t("g.send.invalidInput")
          );
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
button {
  &:disabled {
    opacity: 0.5;
  }
}
</style>
