<template>
  <b-card>
    <!-- form email -->

    <div v-if="isSendEmail">
      <h4 class="mb-2">{{ $t("g.sendcodetoemail") }}</h4>
      <validation-observer ref="checkEmail">
        <b-form>
          <b-row>
            <!--  email -->
            <b-col md="6">
              <b-form-group :label="$t('g.email')" label-for="email">
                <validation-provider
                  #default="{ errors }"
                  name="email"
                  vid="email"
                  rules="required|email"
                >
                  <b-input-group class="input-group-merge">
                    <b-form-input
                      v-model="email_data"
                      name="email"
                      type="email"
                      :state="errors.length > 0 ? false : null"
                      disabled
                    />
                  </b-input-group>
                  <small class="text-danger">{{
                    errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
                  }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- ** email -->

            <!-- buttons -->
            <b-col cols="12">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mt-1 mr-1"
                @click="submitEmail"
                :disabled="isPending"
              >
                {{ $t("g.Send") }}
              </b-button>
            </b-col>
            <!--/ buttons -->
          </b-row>
        </b-form>
      </validation-observer>
    </div>
    <!-- ** form email -->

    <!-- form pin code -->
    <div v-if="isSendCode">
      <h5 class="mb-2">
        {{ $t("g.codeIsSendEmail") }}, {{ $t("g.pleaseCheckEmail") }} ({{
          email
        }})
      </h5>
      <validation-observer ref="pinCode">
        <b-form>
          <b-row>
            <!--  password -->
            <b-col md="6">
              <b-form-group :label="$t('g.pinCode')" label-for="pin code">
                <validation-provider
                  #default="{ errors }"
                  name="code"
                  vid="code"
                  rules="required|min:6|max:6"
                >
                  <b-input-group class="input-group-merge">
                    <b-form-input
                      v-model="pin_code"
                      name="pin_code"
                      type="number"
                      placeholder="code"
                      :state="errors.length > 0 ? false : null"
                    />
                  </b-input-group>
                  <small class="text-danger">{{
                    errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
                  }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- ** pin code -->

            <!-- buttons -->
            <b-col cols="12">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mt-1 mr-1"
                @click="submitPinCode"
                :disabled="isPending"
              >
                {{ $t("g.sendCode") }}
              </b-button>
            </b-col>
            <!--/ buttons -->
          </b-row>
        </b-form>
      </validation-observer>
    </div>
    <!-- ** form pin code  -->

    <!-- form reset password -->
    <div v-if="isSendResetPassword">
      <h4 class="mb-2">{{ $t("g.newPassword") }}</h4>
      <validation-observer ref="reset">
        <b-form>
          <b-row>
            <!--  password -->
            <b-col md="6">
              <b-form-group :label="$t('g.password')" label-for="password">
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  vid="Password"
                  rules="required|min:8|password"
                >
                  <b-input-group class="input-group-merge">
                    <b-form-input
                      v-model="password_data.password"
                      name="password"
                      :type="passwordFieldType"
                      placeholder="Password"
                      :state="errors.length > 0 ? false : null"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        :icon="passwordToggleIcon"
                        class="cursor-pointer"
                        @click="togglePassword"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{
                    errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
                  }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- **  password -->

            <!-- password confirmation -->
            <b-col md="6">
              <b-form-group
                label-for="password_confirmation"
                :label="$t('g.passwordConfirmation')"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Confirm Password"
                  rules="required|confirmed:Password"
                >
                  <b-input-group class="input-group-merge">
                    <b-form-input
                      v-model="password_data.password_confirmation"
                      :type="passwordFieldType"
                      name="password-confirmation"
                      placeholder="Password confirmation"
                      :state="errors.length > 0 ? false : null"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        :icon="passwordToggleIcon"
                        class="cursor-pointer"
                        @click="togglePassword"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{
                    errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
                  }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- ** password confirmation -->

            <!-- buttons -->
            <b-col cols="12">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mt-1 mr-1"
                @click="submitNewPassword"
                :disabled="isPending"
              >
                {{ $t("g.sendNewPassword") }}
              </b-button>
            </b-col>
            <!--/ buttons -->
          </b-row>
        </b-form>
      </validation-observer>
    </div>
    <!-- ** form reset password -->
  </b-card>
</template>

<script>
import {
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BCard,
  BInputGroup,
  BInputGroupAppend,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { ValidationProvider, ValidationObserver } from "vee-validate";

import { required, email, password, confirmed, min, max } from "@validations";

export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BInputGroup,
    BInputGroupAppend,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  props: {
    email: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      passwordFieldType: "password",

      password_data: {
        password: null,
        password_confirmation: null,
      },

      email_data: null,
      pin_code: null,

      isPending: false,

      isSendEmail: true,
      isSendCode: false,
      isSendResetPassword: false,
    };
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
  },

  created() {
    this.email_data = this.email;
  },

  methods: {
    togglePassword() {
      this.passwordFieldType =
        this.passwordFieldType === "password" ? "text" : "password";
    },

    makeToast(variant = null, body, title) {
      this.$bvToast.toast(this.$t(body), {
        title: this.$t(title),
        variant,
        solid: true,
      });
    },

    submitEmail() {
      this.$refs.checkEmail.validate().then((success) => {
        if (success) {
          let formData = new FormData();
          formData.append("email", this.email_data);

          this.isPending = true;

          this.$http
            .post("auth/check-email", formData)
            .then((res) => {
              if (res.status === 200) {
                this.makeToast(
                  "success",
                  this.$t("g.send.successBody"),
                  this.$t("g.send.successTitle")
                );
                setTimeout(() => {
                  this.isSendEmail = false;
                  this.isSendCode = true;
                  this.isSendResetPassword = false;
                }, 1000);
                this.isPending = false;
              }
            })
            .catch((err) => {
              for (const key in err.response.data.errors) {
                this.makeToast(
                  "danger",
                  err.response.data.errors[key][0],
                  this.$t("g.send.errorTitle")
                );
              }
              console.log(err);
              this.isPending = false;
            });
        } else {
          this.makeToast(
            "warning",
            this.$t("g.send.invalidInputBody"),
            this.$t("g.send.invalidInput")
          );
        }
      });
    },

    submitPinCode() {
      this.$refs.pinCode.validate().then((success) => {
        if (success) {
          let formData = new FormData();
          formData.append("pin_code", this.pin_code);

          this.isPending = true;

          this.$http
            .post("auth/reset/check_code", formData)
            .then((res) => {
              if (res.status === 200) {
                // cosnole.log(res.data.data)
                this.makeToast(
                  "success",
                  this.$t("g.send.successBody"),
                  this.$t("g.send.successTitle")
                );
                setTimeout(() => {
                  this.isSendEmail = false;
                  this.isSendCode = false;
                  this.isSendResetPassword = true;
                }, 1000);
                this.isPending = false;
              }
            })
            .catch((err) => {
              for (const key in err.response.data.errors) {
                this.makeToast(
                  "danger",
                  err.response.data.errors[key][0],
                  this.$t("g.send.errorTitle")
                );
              }
              console.log(err);
              this.isPending = false;
            });
        } else {
          this.makeToast(
            "warning",
            this.$t("g.send.invalidInputBody"),
            this.$t("g.send.invalidInput")
          );
        }
      });
    },

    submitNewPassword() {
      this.$refs.reset.validate().then((success) => {
        if (success) {
          let formData = new FormData();
          for (let item in this.password_data) {
            formData.append(item, this.password_data[item]);
          }

          this.isPending = true;

          this.$http
            .post("auth/reset/reset_password", formData)
            .then((res) => {
              if (res.status === 200) {
                const { data } = res.data;
                if (data.token) {
                  localStorage.setItem("accessToken", data.token);
                }
                this.makeToast(
                  "success",
                  this.$t("g.send.successBody"),
                  this.$t("g.send.successTitle")
                );
                setTimeout(() => {
                  this.isSendEmail = true;
                  this.isSendCode = false;
                  this.isSendResetPassword = false;
                }, 1000);
                this.isPending = false;
              }
            })
            .catch((err) => {
              for (const key in err.response.data.errors) {
                this.makeToast(
                  "danger",
                  err.response.data.errors[key][0],
                  this.$t("g.send.errorTitle")
                );
              }
              console.log(err);
              this.isPending = false;
            });
        } else {
          this.makeToast(
            "warning",
            this.$t("g.send.invalidInputBody"),
            this.$t("g.send.invalidInput")
          );
        }
      });
    },
  },
};
</script>
