<template>
  <loading v-if="isLoading" />
  <error v-else-if="isError" />
  <div v-else>
    <b-card>
      <b-tabs
        vertical
        content-class="col-12 col-md-9 mt-1 mt-md-0"
        pills
        nav-wrapper-class="col-md-3 col-12"
        nav-class="nav-left"
      >
        <!-- general tab -->
        <b-tab active>
          <!-- title -->
          <template #title>
            <feather-icon icon="UserIcon" size="18" class="mr-50" />
            <span class="font-weight-bold">{{ $t("g.General") }}</span>
          </template>

          <account-general :data="data" />
        </b-tab>
        <!--/ general tab -->

        <!-- change password tab -->
        <b-tab>
          <!-- title -->
          <template #title>
            <feather-icon icon="LockIcon" size="18" class="mr-50" />
            <span class="font-weight-bold">{{ $t("g.ChangePassword") }}</span>
          </template>

          <account-password :email="data.email" />
        </b-tab>
        <!--/ change password tab -->

        <!-- info -->
        <!-- <b-tab> -->
        <!-- title -->
        <!-- <template #title> -->
        <!-- <feather-icon icon="InfoIcon" size="18" class="mr-50" /> -->
        <!-- <span class="font-weight-bold">{{ $t('g.Information') }}</span> -->
        <!-- </template> -->

        <!-- <account-information :data="data" /> -->
        <!-- </b-tab> -->

        <!-- notification -->
        <!-- <b-tab> -->
        <!-- title -->
        <!-- <template #title> -->
        <!-- <feather-icon icon="BellIcon" size="18" class="mr-50" /> -->
        <!-- <span class="font-weight-bold">Notifications</span> -->
        <!-- </template> -->

        <!-- <account-notification /> -->
        <!-- </b-tab> -->
      </b-tabs>
    </b-card>
  </div>
</template>

<script>
import { BTabs, BTab, BCard } from "bootstrap-vue";
import AccountGeneral from "./AccountGeneral.vue";
import AccountPassword from "./AccountPassword.vue";
import AccountInformation from "./AccountInformation.vue";
import AccountNotification from "./AccountNotification.vue";
import Loading from "@/views/components/logic/loading.vue";
import Error from "@/views/components/logic/error.vue";
export default {
  components: {
    BTabs,
    BTab,
    AccountGeneral,
    AccountPassword,
    AccountInformation,
    AccountNotification,
    Loading,
    Error,
    BCard,
  },
  data() {
    return {
      data: {},
      isLoading: true,
      isError: null,
    };
  },
  created() {
    this.$http
      .get("web/users")
      .then((res) => {
        if (res.status === 200) {
          const { data } = res.data;
          console.log(data);
          this.data = data;
          this.isError = false;
          this.isLoading = false;
        }
      })
      .catch((err) => {
        console.log(err.message);
        this.isError = true;
        this.isLoading = false;
        const { response } = err;
        console.log(response?.data);
        if (response?.status === 401) {
          localStorage.clear();
          window.location.href = "/";
        }
      });
  },
};
</script>
